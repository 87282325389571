import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Row,
  Col,
  Space,
  Button,
  PageHeader,
  Tooltip,
  InputNumber,
  message,
} from 'antd';
import {
  MinusCircleFilled,
  PlusCircleFilled,
  UpCircleFilled,
  DownCircleFilled,
} from '@ant-design/icons';
import ReferenceBlock from './RefrenceBlock';

import '../css/App.css';
// import { Content } from 'antd/lib/layout/layout';
// import ScriptResult from './ScriptResult';

const { Column, ColumnGroup } = Table;
const { TextArea } = Input;

function App() {
  const setFocusNewChar = () => {
    //Add id to the table
    document.getElementsByTagName('TABLE')[0].setAttribute('id', 'mytable');
    let cell =
      document.getElementById('mytable').lastElementChild.lastElementChild
        .previousElementSibling.firstElementChild.children[0].lastElementChild
        .lastElementChild;
    cell.focus();
  };
  const setFocusNewBlock = () => {
    //Add id to the table
    document.getElementsByTagName('TABLE')[0].setAttribute('id', 'mytable');
    let cell =
      document.getElementById('mytable').lastElementChild.firstElementChild
        .lastElementChild.previousElementSibling.firstElementChild;
    cell.focus();
  };

  const [jsonData, setJsonData] = useState('');
  const [data, setData] = useState({
    A: new Array(3).fill(''),
    B: new Array(3).fill(''),
    C: new Array(3).fill(''),
    addChar: new Array(3).fill(''),
  });
  const [charMap, setCharMap] = useState({}); // used to map A,B,C.. to the real characteristic user entered
  const [inputType, setInputType] = useState('Table');
  // const [inputType, setInputType] = useState('JSON');
  const [method, setMethod] = useState('');
  const [addCharMultiple, setAddCharMultiple] = useState(1);
  const [addBlockMultiple, setAddBlockMultiple] = useState(1);

  useEffect(() => {
    const { addChar, ...rest } = data;
    if (data && charMap) updateJsonData(rest, charMap);
  }, [data, charMap]);

  // Handle Functions
  const updateJsonData = (rest, charMap) => {
    const a = Object.fromEntries(
      Object.entries(rest).map(([k, v], index) => {
        if (!charMap[k]) {
          let letter = String.fromCharCode(index + 65);
          if (Object.values(charMap).includes(letter)) {
            while (Object.values(charMap).includes(letter)) {
              letter = letter + Math.floor(9 * Math.random());
            }
          }
          return [letter, v];
        }
        return [charMap[k], v];
      })
    );
    setJsonData(JSON.stringify(a, null, 4));
  };

  const handleAddBlock = (m) => {
    const newData = { ...data };
    const values = Object.values(data);
    let numBlocks = Math.max(...values.map((e) => e.length));

    if (numBlocks >= 10) {
      message.warning('You can have maximum of 10 blocks');
    }
    m = Math.min(m, 10 - numBlocks);
    let i = 0;
    while (i < m) {
      Object.keys(newData).forEach((d) => newData[d].push(''));
      i++;
    }
    setData(newData);
  };
  const handleDeleteBlock = (b) => {
    const newData = { ...data };
    if (Object.values(newData)[0].length < 3) {
      message.warning('At least 2 blocks are required', 2.5);
      return;
    }
    Object.keys(newData).forEach((d) => newData[d].splice(b, 1));
    setData(newData);
  };

  const handleAddChar = (m) => {
    const { addChar, ...rest } = data;
    const blockLen = Math.max(...Object.values(rest).map((e) => e.length));
    let charCount = 0;
    let newChar = String.fromCharCode(charCount + 65);
    for (let i = 0; i < m; i++) {
      while (rest[String.fromCharCode(charCount + 65)]) {
        charCount++;
        newChar = String.fromCharCode(charCount + 65);
      }
      rest[newChar] = new Array(blockLen).fill('');
    }

    setData({
      ...rest,
      addChar,
    });
    return;
  };
  const handleDeleteChar = (c) => {
    const newData = { ...data };
    const newCharMap = { ...charMap };
    if (Object.keys(newData).length < 3) {
      message.warning('At least 1 characteristic is required', 2.5);
      return;
    }

    delete newData[c];
    delete newCharMap[c];
    setData(newData);
    setCharMap(newCharMap);
  };

  const handleEnterChar = (absChar, realChar) => {
    setCharMap((prev) => ({
      ...prev,
      [absChar]: realChar,
    }));
  };
  const handleEnterBlock = (absChar, pos, newBlock) => {
    let blocks = data[absChar];
    blocks[pos] = newBlock;
    setData((prev) => ({
      ...prev,
      [absChar]: blocks,
    }));
  };

  const handleSubmit = (m) => {
    setMethod(m);
  };

  const handleReset = () => {
    const newData = {
      A: new Array(3).fill(''),
      B: new Array(3).fill(''),
      C: new Array(3).fill(''),
      // A: new Array(3).fill('a'),
      // B: new Array(3).fill('b'),
      // C: new Array(3).fill('c'),
      addChar: new Array(3).fill(''),
    };
    setData(newData);
    setAddCharMultiple(1);
    setAddBlockMultiple(1);
    setCharMap({});
    setMethod('');
  };

  const handleSample = () => {
    setData({
      A: ['Honda', 'Tesla', 'Lexus', 'Toyota'],
      B: ['2022', '2021', '2020 or earlier'],
      C: ['sedan', 'coupe', 'suv', 'other'],
      D: ['gas', 'hybrid', 'electric'],
      addChar: new Array(4).fill(''),
    });
    setCharMap({
      A: 'make',
      B: 'year',
      C: 'body type',
      D: 'fuel type',
    });
  };

  const methodMenu = {
    All: 'All',
    'All combinations': 'ACoC',
    'Each choice': 'ECC',
    'Base choice': 'BCC',
  };

  const { addChar, ...rest } = data;
  const blocks = Object.values(rest);
  const longest = Math.max(...blocks.map((e) => e.length));
  const longestindex = blocks.findIndex((i) => i.length === longest);

  const saveAsJson = new Map();
  for (const [key, value] of Object.entries(data)) {
    if (charMap[key] !== undefined) {
      saveAsJson.set(charMap[key], value);
    }
  }
  const setDataFromJson = (content, from, next) => {
    try {
      const obj = JSON.parse(content);
      for (let b of Object.values(obj)) {
        if (!(b instanceof Array)) {
          message.warning(from + ' has incorrect JSON syntax');
          return;
        }
      }
      setData({
        ...Object.values(obj),
        addChar: new Array(
          Math.max(...Object.values(obj).map((e) => e.length))
        ).fill(''),
      });
      setCharMap({ ...Object.keys(obj) });
      if (next) next(inputType === 'JSON' ? 'Table' : 'JSON');
    } catch (e) {
      console.log(e);
      message.warning(from + ' was not in JSON format');
      return;
    }
  };

  let fileReader;
  const handleFileRead = (e) => {
    const content = fileReader.result;
    setDataFromJson(content, 'File uploaded');
  };

  const handleFileChosen = (file) => {
    if (file !== '') {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);
    }
  };

  return (
    <Row style={{ margin: 'auto', width: '100%', height: '100%', padding: 50 }}>
      <Col span={16} offset={4}>
        <PageHeader
          className="isp-header"
          backIcon={false}
          title="Input-Space Partitioning"
          extra={[
            <Space key="title">
              <Tooltip title="apply sample option" placement="top">
                <Button tabIndex="-1" onClick={() => handleSample()}>
                  Sample
                </Button>
              </Tooltip>
              <Tooltip title="Load JSON file to table" placement="top">
                <input
                  tabIndex="-1"
                  type="file"
                  accept=".json"
                  style={{ display: 'none' }}
                  id="file"
                  onChange={(e) => {
                    handleFileChosen(e.target.files[0]);
                    e.target.value = '';
                  }}
                />
                <label
                  htmlFor="file"
                  style={{
                    background: 'white',
                    border: '1px solid rgba(0, 0, 0, 0.15)',
                    padding: '4px 15px',
                    borderRadius: 2,
                    cursor: 'pointer',
                    boxShadow: '0 2px 0 rgb(0 0 0 / 2%)',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Load from JSON file
                </label>
              </Tooltip>
            </Space>,
          ]}
        />
        {inputType === 'Table' && (
          <>
            <Row
              justify="center"
              style={{
                marginBottom: 20,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Table
                rowKey={(e) => e}
                className="isp-char-table"
                dataSource={Object.keys(data)}
                pagination={false}
                scroll={
                  data.length
                    ? {
                        x: 'max-content',
                      }
                    : {
                        // x: 'max-content'
                      }
                }
                bordered
              >
                <Column
                  align="center"
                  width={150}
                  title="Char"
                  fixed="left"
                  onCell={(record, index) => {
                    const size = Object.values(data).length - 1;
                    const mergeSize = Object.values(data)[0].length + 2;
                    if (index === size) {
                      return {
                        colSpan: mergeSize,
                      };
                    }
                    return {};
                  }}
                  render={(text, record, cIndex) => {
                    if (cIndex < Object.keys(data).length - 1) {
                      // console.log(Object.keys(data));
                      // console.log(cIndex);
                      const absChar = Object.keys(data)[cIndex];
                      return (
                        <Space>
                          <Tooltip title="Delete char" placement="left">
                            <MinusCircleFilled
                              style={{ fontSize: '20px', color: '#d62424' }}
                              onClick={() => handleDeleteChar(absChar)}
                            />
                          </Tooltip>
                          <Input
                            tabIndex="1"
                            onChange={(e) =>
                              handleEnterChar(absChar, e.target.value)
                            }
                            value={charMap[absChar]}
                          />
                        </Space>
                      );
                    } else {
                      return (
                        <Space>
                          <Tooltip title="Add char" placement="left">
                            <PlusCircleFilled
                              style={{ fontSize: '20px', color: '#5468ff' }}
                              onClick={() => {
                                handleAddChar(addCharMultiple);
                                window.requestAnimationFrame(setFocusNewChar);
                              }}
                            />
                          </Tooltip>
                          <InputNumber
                            tabIndex="2"
                            min="1"
                            max="9"
                            size="small"
                            style={{ width: '48px' }}
                            defaultValue={addCharMultiple}
                            value={addCharMultiple}
                            onStep={(e) => {
                              setAddCharMultiple(e);
                            }}
                            onChange={(e) => {
                              setAddCharMultiple(e);
                            }}
                          />
                        </Space>
                      );
                    }
                  }}
                />
                <ColumnGroup title="Block (max: 10)">
                  {Object.values(data).length &&
                    Object.values(data)[longestindex].map((d, dIndex) => (
                      <Column
                        key={'block' + dIndex}
                        align="center"
                        title={() => (
                          <Tooltip
                            key={'blocktip' + dIndex}
                            title="Delete block"
                            placement="top"
                          >
                            <MinusCircleFilled
                              style={{ fontSize: '20px', color: '#d62424' }}
                              onClick={() => handleDeleteBlock(dIndex)}
                            />
                          </Tooltip>
                        )}
                        render={(text, record, cIndex) => {
                          const absChars = Object.keys(data);
                          const currentChar = absChars[cIndex];
                          if (cIndex === 0 && dIndex === 0) {
                            // Block Input
                            return (
                              <Input
                                tabIndex="1"
                                autoFocus
                                value={data[currentChar][dIndex]}
                                onChange={(e) =>
                                  handleEnterBlock(
                                    currentChar,
                                    dIndex,
                                    e.target.value
                                  )
                                }
                              />
                            );
                          } else if (cIndex < absChars.length) {
                            // Block Input
                            return (
                              <Input
                                tabIndex="1"
                                value={data[currentChar][dIndex]}
                                onChange={(e) =>
                                  handleEnterBlock(
                                    currentChar,
                                    dIndex,
                                    e.target.value
                                  )
                                }
                              />
                            );
                          } else return null;
                        }}
                        onCell={(_, index) => {
                          const size = Object.values(data).length - 1;
                          if (index === size) {
                            return {
                              colSpan: 0,
                            };
                          }
                          return {};
                        }}
                      />
                    ))}
                  <Column
                    onCell={(_, index) => {
                      const size = Object.values(data).length - 1;
                      const span = {
                        rowSpan: index === 0 ? size : 0,
                      };
                      if (index === size) {
                        return {
                          ...span,
                          colSpan: 0,
                        };
                      }
                      return span;
                    }}
                    render={(text, record, cIndex) => (
                      <div style={{ textAlign: 'center' }}>
                        <Space direction="vertical" size="small" align="center">
                          <Tooltip title="Add block" placement="right">
                            <PlusCircleFilled
                              style={{ fontSize: '20px', color: '#5468ff' }}
                              onClick={() => {
                                handleAddBlock(addBlockMultiple);
                                window.requestAnimationFrame(setFocusNewBlock);
                              }}
                            />
                          </Tooltip>
                          <InputNumber
                            tabIndex="2"
                            min="1"
                            max="8"
                            size="small"
                            style={{ width: '45px' }}
                            defaultValue={addBlockMultiple}
                            value={addBlockMultiple}
                            onStep={(e) => {
                              setAddBlockMultiple(e);
                            }}
                            onChange={(e) => {
                              setAddBlockMultiple(e);
                            }}
                          />
                        </Space>
                      </div>
                    )}
                  />
                </ColumnGroup>
              </Table>
            </Row>
          </>
        )}
        {inputType === 'JSON' && (
          <Row justify="center">
            <TextArea
              autoFocus
              rows={17}
              value={jsonData}
              onChange={(e) => {
                setJsonData(e.target.value);
              }}
              style={{ marginBottom: 20, width: '75%' }}
            />
          </Row>
        )}
        <Row justify="center" style={{ marginBottom: 20 }}>
          <Space>
            <Button
              style={{ backgroundColor: '' }}
              onClick={() => {
                setDataFromJson(jsonData, 'Text input', setInputType);
                // setInputType(inputType === 'JSON' ? 'Table' : 'JSON');
              }}
            >
              {inputType === 'JSON' ? 'Apply JSON to Table' : 'Use JSON Editor'}
            </Button>
            <Button style={{ backgroundColor: '' }} onClick={handleReset}>
              Reset
            </Button>
            <Button
              style={{ backgroundColor: '' }}
              href={`data:text/json;charset=utf-8,${encodeURIComponent(
                JSON.stringify(Object.fromEntries(saveAsJson))
              )}`}
              download="input.json"
            >
              Save as JSON
            </Button>
          </Space>
        </Row>
        <Row justify="center">
          <Space>
            Generate using:
            {Object.keys(methodMenu).map((m, mIndex) => {
              let title = '';
              let placement = 'bottom';
              switch (m) {
                case 'All combinations':
                  title =
                    'Every block in every characteristic is combined with every other block.';
                  break;
                case 'Each choice':
                  title =
                    'Every block in every characteristic appears in at least one combination.';
                  break;
                case 'Base choice':
                  title =
                    'Base combination (all the base blocks) plus one combination for each other block, swapped in one at a time. Block 1 from each Char will always be base block.';
                  placement = 'right';
                  break;
                default: // All
                  title = 'Run all three methods.';
                  break;
              }
              return (
                <Tooltip
                  key={'method' + mIndex}
                  title={title}
                  placement={placement}
                >
                  <Button
                    style={{ backgroundColor: 'lightBlue' }}
                    key={m}
                    onClick={() => handleSubmit(methodMenu[m])}
                  >
                    {m}
                  </Button>
                </Tooltip>
              );
            })}
          </Space>
        </Row>
        <ReferenceBlock data={data} charMap={charMap} method={method} />
      </Col>
      <div
        className="navigation"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '30px',
          zIndex: '99',
        }}
      >
        <UpCircleFilled
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          style={{ fontSize: '40px', color: 'rgb(215, 166, 131)' }}
        />
        <DownCircleFilled
          onClick={() => {
            document.getElementById('scroll')?.scrollIntoView();
          }}
          style={{
            paddingLeft: '2px',
            fontSize: '40px',
            color: 'rgb(215,166,131)',
          }}
        />
      </div>
    </Row>
  );
}

export default App;
