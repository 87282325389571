const mapRealWithAbstract = (data) => {
  return Object.keys(data).map((c) =>
    data[c].map((b, i) => (b.trim() !== '' ? c + (i + 1) : b.trim()))
  );
};

// convert abstract test result to real
const mapAbstractWithReal = (data, abstractResult, charMap) => {
  // const charMap = Object.keys(data);
  return abstractResult.map((blocks) => {
    return blocks.map((b) => {
      if (!b) {
      } else if (b.includes('*')) {
        return (
          'any from ' +
          // String.fromCharCode(charMap.indexOf(b.substring(0, 1)) + 65)
          charMap[b.substring(0, 1)]
        );
      } else if (b !== '') {
        const char = b.substring(0, 1);
        const index = b.substring(1, b.length);
        return data[char][parseInt(index) - 1];
      }
      return b;
    });
  });
};

const extractAndremoveBlankFromBlocks = (data) => {
  const { addChar, ...restt } = data;
  const rest = mapRealWithAbstract(restt);
  const blocks = Object.values(rest);
  let res = [];

  for (let b of blocks) {
    let temp = [];
    // console.log(b);
    temp = b.filter((e) => e !== '');
    if (temp.length !== 0) res.push(temp); // to not add the char with empty blocks
  }
  return res;
};
function ECC(data, charMap) {
  const blocks = extractAndremoveBlankFromBlocks(data);
  const charData = Object.keys(data);
  // Find the number of blocks in the characteristic with the most blocks
  let mostBlocks = 0;
  for (let c of blocks) {
    if (c.length > mostBlocks) {
      mostBlocks = c.length;
    }
  }
  // Return abstract tests in each choice order
  let tests = [];
  let realtests = [];

  for (let testNum = 0; testNum < mostBlocks; testNum++) {
    let test = [];
    let realtest = [];
    let charcount = 0;
    for (let c of blocks) {
      let letter = String.fromCharCode(charcount + 65);
      // check if we've displayed all blocks
      if (testNum < c.length) {
        // Nth block in characteristic C
        let realblock = c[testNum];
        test.push(letter + realblock.substring(1, realblock.length));
        // realtest.push(charData[charcount] + (testNum + 1));
        realtest.push(realblock);
      } else {
        // no more blocks in this characteristic, any value will do
        test.push(letter + '*');
        // test.push(letter + '*');
        realtest.push(charData[charcount] + '*');
      }
      charcount++;
    }
    tests.push(test);
    realtests.push(realtest);
  }
  return {
    real: mapAbstractWithReal(data, realtests, charMap),
    abstract: tests,
  };
}

function BCC(data, charMap) {
  const blocks = extractAndremoveBlankFromBlocks(data);
  // const charData = Object.keys(data);

  let baseTest = []; // a list of blocks for the base
  let baseRealTest = [];
  // create base test
  let i = 0;
  for (let c of blocks) {
    let realblock = c[0];
    let letter = String.fromCharCode(i + 65);
    baseTest.push(letter + realblock.substring(1, realblock.length));
    baseRealTest.push(realblock);
    i++;
  }

  let charNum = 0;
  let currentTest = null;
  let currentRealTest = null;
  let tests = [];
  let realtests = [];
  if (baseTest.length !== 0) {
    tests.push(baseTest);
    realtests.push(baseRealTest);
  }
  for (let c of blocks) {
    // skip first block (base)
    for (let blockNum = 1; blockNum < c.length; blockNum++) {
      currentTest = [...baseTest];
      currentRealTest = [...baseRealTest];
      let realblock = c[blockNum];
      let letter = String.fromCharCode(charNum + 65);
      currentTest[charNum] = letter + realblock.substring(1, realblock.length);
      currentRealTest[charNum] = realblock;
      tests.push(currentTest);
      realtests.push(currentRealTest);
    }
    charNum++;
  }

  return {
    real: mapAbstractWithReal(data, realtests, charMap),
    abstract: tests,
  };
}

function ACoC(data, charMap) {
  const blocks = extractAndremoveBlankFromBlocks(data);
  const numChars = blocks.length;
  // const charData = Object.keys(data);
  // Find the number of tests
  let numTests = 1;
  for (let c of blocks) {
    numTests = numTests * c.length;
  }

  let tests = [];
  let realtests = [];
  let cIndex = new Array(numChars).fill(0); // numChars index variables

  for (let testNum = 0; testNum < numTests; testNum++) {
    let test = [];
    let realtest = [];
    for (let j = 0; j < numChars; j++) {
      let letter = String.fromCharCode(j + 65);
      let realblock = blocks[j][cIndex[j]];
      test.push(letter + realblock.substring(1, realblock.length));
      realtest.push(realblock);
    }
    if (test.length !== 0) {
      tests.push(test);
      realtests.push(realtest);
    }
    // If we finished a characteristic, wrap around
    // check each C in blocks in reverse order
    for (let i = numChars - 1; i >= 0; i--) {
      if (cIndex[i] === blocks[i].length - 1) {
        cIndex[i] = 0; // back to the beginning of this characteristic, check next
      } else {
        // If we don't finish the previous characteristic
        // increase it by one and BREAK
        cIndex[i]++;
        break;
      }
    }
  }

  return {
    real: mapAbstractWithReal(data, realtests, charMap),
    abstract: tests,
  };
}

export { ACoC, ECC, BCC };
