import React, { useRef, useState, useEffect } from 'react';
import { Table, Button, Row } from 'antd';
import ScriptResult from './ScriptResult';
const { Column, ColumnGroup } = Table;

function getWindowSize () {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const ReferenceBlock = ({ data, charMap, method }) => {
  const blockRef = useRef(null);
  const resultRef = useRef(null);
  const functionRef = useRef(null);
  const [blockWidth, setBlockWidth] = useState(0);
  const [resultWidth, setResultWidth] = useState(0);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          if (entry.target.id === 'blockRef') {
            setBlockWidth(entry.contentRect.width);
          } else if (entry.target.id === 'resultRef') {
            setResultWidth(entry.contentRect.width);
          }
        }
      }
    });
    resizeObserver.observe(blockRef.current);
    resizeObserver.observe(resultRef.current);

    function handleWindowResize () {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      resizeObserver.unobserve(blockRef.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      resizeObserver.unobserve(resultRef.current);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const [refType, setRefType] = useState('Real');
  if (!data) {
    return;
  }
  //   console.log(data);
  const { addChar, ...chars } = data;

  //   console.log(Object.keys(chars));
  //   console.log(Object.values(chars));
  const charKey = Object.keys(chars);
  const blocks = Object.values(chars);
  const longest = Math.max(...blocks.map((e) => e.length));
  const longestindex = blocks.findIndex((i) => i.length === longest);

  // console.log(windowSize.innerWidth);

  return (
    <Row
      justify="center"
      align="middle"
      className={method ? '' : 'abstractTable'}
      style={method ? { marginTop: 20, marginBottom: 10 } : {}}
    >
      <div
        id="blockRef"
        ref={blockRef}
        className={
          resultWidth === 0
            ? 'referenceBlock'
            : blockWidth + resultWidth + 81 >=
              windowSize.innerWidth * 0.61361512261
              ? 'referenceBlock-vertical'
              : 'referenceBlock-horizontal'
        }
      >
        <Table
          className="isp-abs-table"
          rowKey={(e) => e}
          dataSource={charKey}
          pagination={false}
          size="middle"
          bordered
        >
          <ColumnGroup title="Reference">
            <Column
              title={<span style={{ fontWeight: 'bold' }}>Char</span>}
              align="center"
              render={(text, record, cIndex) => {
                if (refType === 'Real') {
                  return String.fromCharCode(cIndex + 65) || '-';
                  // because when running script.js, it doenst care about key names
                } else {
                  return charMap[charKey[cIndex]] || '-'; // show characteristc entered
                }
              }}
            />
            <ColumnGroup
              title={<span style={{ fontWeight: 'bold' }}>Block</span>}
            >
              {Object.values(data).length &&
                Object.values(data)[longestindex].map((d, dIndex) => (
                  <Column
                    key={`absblock${dIndex}`}
                    render={(text, record, cIndex) => {
                      if (refType === 'Real') {
                        return blocks[cIndex][dIndex]
                          ? String.fromCharCode(cIndex + 65) + (dIndex + 1)
                          : '-';
                        // return (
                        //   // String.fromCharCode(cIndex + 65) + (dIndex + 1)
                      } else {
                        return blocks[cIndex][dIndex] || '-';
                      }
                    }}
                  />
                ))}
            </ColumnGroup>
          </ColumnGroup>
        </Table>
        <div style={{ paddingTop: 10 }}></div>
        <Button
          onClick={() => setRefType(refType === 'Real' ? 'Abstract' : 'Real')}
          style={method === 'All' ? { marginBottom: 10 } : {}}
        >
          {'Show ' + refType}
        </Button>
        {method === 'All' &&
          <Button onClick={() => functionRef.current.handleDownloadAll()}>
            Download All as JSON
          </Button>
        }
      </div>
      <ScriptResult
        className={
          blockWidth + resultWidth + 81 >= windowSize.innerWidth * 0.61361512261
            ? 'result-vertical'
            : 'result-horizontal'
        }
        style={method ? {} : { display: 'none' }}
        data={data}
        method={method}
        innerWidth={windowSize.innerWidth}
        ref={{
          resultRef,
          functionRef
        }}
        charMap={charMap}
      />
    </Row>
  );
};

export default ReferenceBlock;
