import React, { useState, useImperativeHandle } from 'react';
import { Button, Tooltip, message } from 'antd';
import { Input, Col, Space } from 'antd';
import { ACoC, ECC, BCC } from './script';

const { TextArea } = Input;

const ScriptResult = React.forwardRef(
  ({ data, method, style, className, charMap }, { resultRef, functionRef }) => {
    const [acocType, setACoCType] = useState('Real');
    const [eccType, setECCType] = useState('Real');
    const [bccType, setBCCType] = useState('Real');
    const acoc = ACoC(data, charMap);
    const bcc = BCC(data, charMap);
    const ecc = ECC(data, charMap);

    let result = {};
    switch (method) {
      case 'ACoC':
        result.ACoC = acoc;
        break;
      case 'ECC':
        result.ECC = ecc;
        break;
      case 'BCC':
        result.BCC = bcc;
        break;
      default: // All
        result.ACoC = acoc;
        result.ECC = ecc;
        result.BCC = bcc;
    }

    const handleDownloadAll = () => {
      Object.keys(result).forEach(r => {
        let resultShowing = result[r].abstract;
        if (updateRef[r].value !== 'Real') {
          resultShowing = result[r].real;
        }
        const prettyPrintResult = JSON.stringify(
          resultShowing.map((a) => JSON.stringify(a)),
          null,
          2
        )
          .replace(/\\/g, '')
          .replace(/"\[/g, '[')
          .replace(/\]"/g, ']');
        const blob = new Blob([prettyPrintResult], {
          type: 'application/json'
        });
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = `${r}-result.json`;
        document.body.appendChild(element);
        element.click();
      });
    }

    useImperativeHandle(functionRef, () => ({
      handleDownloadAll () {
        handleDownloadAll()
      }
    }));

    // if (acoc.length === 0) {
    //   return;
    // }
    const methodNameMapping = {
      ACoC: 'All Combinations',
      ECC: 'Each Choice',
      BCC: 'Base Choice',
    };
    const updateRef = {
      ACoC: {
        method: setACoCType,
        value: acocType,
      },
      ECC: {
        method: setECCType,
        value: eccType,
      },
      BCC: {
        method: setBCCType,
        value: bccType,
      },
    };

    return (
      <div ref={resultRef} style={style} id="resultRef" className={className}>
        <Space>
          {Object.keys(result).map((r) => {
            let resultShowing = result[r].abstract;
            if (updateRef[r].value !== 'Real') {
              resultShowing = result[r].real;
            }
            const prettyPrintResult = JSON.stringify(
              resultShowing.map((a) => JSON.stringify(a)),
              null,
              2
            )
              .replace(/\\/g, '')
              .replace(/"\[/g, '[')
              .replace(/\]"/g, ']');
            return (
              <Col
                key={'result' + r}
                justify="center"
                align="center"
                className="resultDisplay"
              >
                <h2>
                  {methodNameMapping[r]} ({resultShowing.length})
                </h2>
                <TextArea
                  rows={11}
                  disabled
                  style={{
                    marginBottom: 10,
                    color: 'rgba(0, 0, 0, 0.65)',
                  }}
                  value={prettyPrintResult}
                />
                <Button
                  onClick={() =>
                    updateRef[r].method(
                      updateRef[r].value === 'Real' ? 'Abstract' : 'Real'
                    )
                  }
                  style={{ marginBottom: 10 }}
                >
                  {'Show ' + updateRef[r].value}
                </Button>
                <Space>
                  <Tooltip title="Copy to clipboard" placement="top">
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(prettyPrintResult)
                          .then(() => message.success(`Successfully copied "${methodNameMapping[r]}" result`))
                          .catch(() => message.error(`Something went wrong copying "${methodNameMapping[r]}" result, please retry`));
                      }}
                    >
                      Copy
                    </Button>
                  </Tooltip>
                  <Button
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                      prettyPrintResult
                    )}`}
                    download={r + '-result.json'}
                  >
                    Download as JSON
                  </Button>
                </Space>
              </Col>
            );
          })}
        </Space>
      </div>
    );
  }
);

export default ScriptResult;
